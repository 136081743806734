import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Link, Stack, Button, Drawer, Tooltip, Typography, CardActionArea, ListItemText } from '@material-ui/core';
// hooks
import jwtDecode from 'jwt-decode';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// routes
import { PATH_DASHBOARD, PATH_DOCS } from '../../routes/paths';
// components
import Logo from '../../components/Logo';
import LogoMini from '../../components/LogoMini';
import MyAvatar from '../../components/MyAvatar';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import { DocIllustration } from '../../assets';
import Permision from '../../utils/permits';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const [list, setList] = useState(sidebarConfig);
  const ProductImgStyle = styled('img')({
    top: 0,
    width: '200px',
    height: 'auto',
    objectFit: 'fill',
    position: 'static'
  });
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  const deleteCatalog = (list, index, title, del) => {
    const itemIndex = list[index].items.findIndex((item) => item.title === title); // find index of item with title 'catalog'
    if (itemIndex !== -1 && del === false) {
      list[index].items.splice(itemIndex, 1); // remove item at index itemIndex
      setList([...list]); // update state with new list
    }
  };
  const deleteCatalogChildren = (list, index, title, children_, del) => {
    const objetoPadre = list[index].items.findIndex((item) => item.title === title);
    const objetoPadre_ = list[index].items.find((item) => item.title === title);
    if (objetoPadre !== undefined && objetoPadre !== -1 && objetoPadre_.children !== undefined) {
      const indiceHijo = list[index].items[objetoPadre].children.findIndex((child) => child.title === children_);
      if (indiceHijo !== -1 && del === false) {
        list[index].items[objetoPadre].children.splice(indiceHijo, 1);
        setList([...list]); // update state with new list
      }
    }
  };

  useEffect(() => {
    console.log(sidebarConfig);
    const BackdropRowPosologyTemp = JSON.parse(JSON.stringify(sidebarConfig));

    // eslint-disable-next-line array-callback-return
    const updatedList = BackdropRowPosologyTemp.map((items, i) => {
      deleteCatalog(list, i, 'catalog', Permision(1));
      deleteCatalog(list, i, 'patient', Permision(29));
      deleteCatalog(list, i, 'posology', Permision(11));
      deleteCatalog(list, i, 'newpro', Permision(13));
      deleteCatalog(list, i, 'report', Permision(39));
      deleteCatalog(list, i, 'import', Permision(18));
      deleteCatalog(list, i, 'take', Permision(30));
      deleteCatalog(list, i, 'virtual', Permision(20));
      deleteCatalog(list, i, 'traceability', Permision(32));
      deleteCatalog(list, i, 'user', Permision(34));
      deleteCatalog(list, i, 'centers', Permision(35));
      deleteCatalog(list, i, 'My pharmacy', Permision(37));
      deleteCatalogChildren(list, i, 'virtual', 'order', Permision(21));
      deleteCatalogChildren(list, i, 'virtual', 'listrx', Permision(23));
      deleteCatalogChildren(list, i, 'virtual', 'reports', Permision(25));
      deleteCatalogChildren(list, i, 'virtual', 'merge', Permision(26));
      deleteCatalogChildren(list, i, 'virtual', 'parameters', Permision(27));

      deleteCatalogChildren(list, i, 'report', 'reqwork', Permision(40));
      // deleteCatalogChildren(list, i, 'traceability', 'alerts', Permision(39));
      // deleteCatalogChildren(list, i, 'traceability', 'history', Permision(39));

      deleteCatalogChildren(list, i, 'user', 'my profile', Permision(34));
      deleteCatalogChildren(list, i, 'user', 'list', Permision(33));

      deleteCatalogChildren(list, i, 'centers', 'centersmanage', Permision(35));

      deleteCatalogChildren(list, i, 'My pharmacy', 'subscription', Permision(37));
    });

    console.log(updatedList);
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const ListItemStyle = styled((props) => <ListItemText disableGutters {...props} />)(({ theme }) => ({
    ...theme.typography.body2,
    height: 28,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    textTransform: 'capitalize',
    color: theme.palette.text.secondary,
    '&:before': {
      top: 0,
      right: 0,
      width: 3,
      bottom: 0,
      content: "''"
    }
  }));

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            {isCollapse ? (
              <LogoMini sx={{ width: 35, height: 35 }} gcolor="#ffcc80" />
            ) : (
              <Logo sx={{ width: 210, height: 'auto' }} gcolor="#0045FF" alignItems="center" />
            )}
          </Box>

          <MHidden width="lgDown">
            {!isCollapse && <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />}
          </MHidden>
        </Stack>
      </Stack>
      <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
      <Box sx={{ flexGrow: 1 }} />
      <Stack direction="row" justifyContent="center">
        <ListItemStyle disableTypography primary="V 7.8.1" />
      </Stack>
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
