import axios from 'axios';
import { hostApi } from '../config';
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: hostApi,
  headers: { Lang: localStorage.getItem('i18nextLng') }
});
// eslint-disable-next-line react-hooks/rules-of-hooks
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      window.location = '/';
      localStorage.removeItem('accessToken');
      delete axiosInstance.defaults.headers.common.Authorization;
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;
