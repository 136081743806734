import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

LogoMini.propTypes = {
  sx: PropTypes.object,
  gcolor: PropTypes.string
};

export default function LogoMini({ sx, gcolor }) {
  return (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg id="outputsvg" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 820 820">
        <g id="l5dapXQhPbkNS3YBdNhcO7O" fill="#022689">
          <g>
            <path
              id="pE1LX06EU"
              d="M217 780 c-54 -14 -82 -35 -116 -89 -37 -56 -44 -131 -13 -131 9 0 24 15 32 32 8 18 31 42 50 53 32 18 53 20 225 20 160 -1 195 -3 222 -18 38 -20 63 -53 63 -84 0 -16 6 -23 20 -23 17 0 20 7 20 53 0 65 -38 132 -96 168 -36 22 -49 24 -204 26 -91 1 -182 -2 -203 -7z"
            />
            <path
              id="pWflKXr7R"
              d="M360 530 l0 -40 -40 0 -40 0 0 -40 0 -40 40 0 40 0 0 -40 c0 -39 1 -40 34 -40 33 0 35 2 38 38 3 36 4 37 46 40 42 3 42 3 42 43 l0 39 -45 0 -45 0 0 40 c0 39 -1 40 -35 40 -34 0 -35 -1 -35 -40z"
            />
            <path
              id="pFlv7zPmf"
              d="M312 161 l-42 -6 61 -68 c34 -37 65 -67 69 -65 16 6 119 132 114 140 -3 4 -23 8 -44 8 -31 0 -43 -5 -56 -25 l-16 -24 -21 23 c-18 18 -29 21 -65 17z"
            />
          </g>
        </g>
        <g id="l6ZQ8ShCriICKjkOMyg4C8W" fill="#0045FF">
          <g>
            <path
              id="pLONY5Ym7"
              d="M660 722 c26 -38 35 -95 26 -147 -4 -21 -43 -90 -91 -161 l-83 -123 65 1 c62 2 66 4 100 41 100 112 96 271 -10 390 l-29 32 22 -33z"
            />
            <path
              id="p7mRzuZ9B"
              d="M85 667 c-67 -115 -59 -231 23 -325 36 -42 39 -44 105 -48 l68 -4 -85 125 c-71 105 -86 133 -91 177 -4 31 -2 65 5 82 17 46 4 42 -25 -7z"
            />
          </g>
        </g>
        <g id="l5oNJ9vNzxodBv4eUN5lcKM" fill="#0045FF">
          <g>
            <path
              id="pg3uiyiYG"
              d="M222 343 c-7 -7 -31 -13 -53 -13 -22 0 -39 -4 -37 -9 2 -6 38 -51 81 -100 54 -62 86 -91 100 -91 12 0 32 -3 45 -6 13 -4 22 -2 22 5 0 7 -33 61 -73 120 -59 88 -75 105 -85 94z"
            />
            <path
              id="pivOLi3Js"
              d="M542 323 c-19 -7 -133 -181 -123 -187 4 -3 25 -5 46 -4 33 1 44 10 116 91 58 66 76 93 67 99 -14 8 -85 10 -106 1z"
            />
          </g>
        </g>
        <g id="l6JJeNyK2ReRthc5vg8T1xL" fill="rgb(255,227,210)">
          <g>
            <path id="paynfrD5n" d="M323 783 c43 -2 110 -2 150 0 40 1 5 3 -78 3 -82 0 -115 -2 -72 -3z" />
          </g>
        </g>
      </svg>
    </Box>
  );
}
