import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import Medication from '@material-ui/icons/Medication';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Stack,
  TextField,
  Card,
  CardHeader,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
// routes
import SearchIcon from '@material-ui/icons/Search';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/named
import { isValidToken, setForcrSession } from '../../utils/jwt';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import SvgIconStyle from '../../components/SvgIconStyle';
// components
import { MIconButton, MAvatar } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import useLocales from '../../hooks/useLocales';
import Scrollbar from '../../components/Scrollbar';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

// ----------------------------------------------------------------------

export default function CenterPopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [InstallID, setInstallID] = useState();
  const handleOpen = () => {
    setOpen(getSuperAdmin());
    getIdCenterLogin();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getSuperAdmin = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);
    console.log(decoded.super_admin);
    return decoded.super_admin;
  };
  const getIdCenterLogin = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);
    setSelectedIndex(Number(decoded.center_login));
    console.log(decoded.center_login);
    return decoded.center_login;
  };
  function handleOnChange(e) {
    handleSubmitlab(e.target.value);
  }
  const { translate } = useLocales();
  const [filter_, setFilter] = useState();
  const [Providers, setProviders] = useState([]);
  const handleSubmitlab = async (filter) => {
    setFilter(filter);
    // setOpenBackdrop(true);
    await axios
      .get(`/api/center/center_list?status=1&filter=${filter}`, Request)
      .then((res) => {
        if (res) {
          setProviders(res.data);
        }
      })
      .catch((error) => {});
    // setOpenBackdrop(false);
  };

  const handleSubmitChangeInstall = async (filter) => {
    setFilter(filter);
    // setOpenBackdrop(true);
    await axios
      .get(`/api/center/center_change?center=${filter}`, Request)
      .then((res) => {
        setForcrSession(res.data.accessToken);
      })
      .catch((error) => {});
    // setOpenBackdrop(false);
  };

  useEffect(() => {
    handleSubmitlab('');
  }, []);

  const tableStyles = styled({
    tableHead: {
      height: 44
    }
  });
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (e) => {
    setInstallID(e);
    setOpenDialog(true);
  };
  const ChangePharma = async (e) => {
    await handleSubmitChangeInstall(InstallID);
    setOpenDialog(false);
    window.location.reload(false);
  };
  const [selectedIndex, setSelectedIndex] = useState(1);
  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <MAvatar
          sx={{
            width: 30,
            height: 30
          }}
          src="/static/icons/navbar/ic_center.svg"
          variant="square"
          alt="Pharma"
        />
      </MIconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 350 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <TextField
            fullWidth
            label={translate('listmedicine.search')}
            type="search"
            variant="standard"
            onChange={handleOnChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Scrollbar sx={{ height: '500px' }}>
            <List>
              {Providers.map((row) => (
                // eslint-disable-next-line react/jsx-key
                <ListItem disablePadding>
                  <ListItemButton
                    sx={{ border: '3px', borderRadius: '22px' }}
                    selected={selectedIndex === row.id}
                    onClick={() => {
                      handleOpenDialog(row.id);
                    }}
                  >
                    <ListItemIcon>
                      <MAvatar
                        sx={{ width: 25, height: 25 }}
                        variant="square"
                        src="/static/icons/navbar/ic_center.svg"
                        alt="Pharma"
                      />
                    </ListItemIcon>
                    <ListItemText primary={row.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Scrollbar>
        </Box>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <Stack spacing={1}>
            <DialogTitle>{translate('centers.change')}</DialogTitle>
            <DialogContent sx={{ width: 400 }} alignItems="center" justifyContent="center">
              <DialogContentText>{translate('centers.sure')}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus variant="contained" color="error" onClick={handleCloseDialog}>
                {translate('listmedicine.cancel')}
              </Button>
              <Button onClick={ChangePharma} variant="contained" autoFocus>
                {translate('centers.acept')}
              </Button>
            </DialogActions>
          </Stack>
        </Dialog>
      </MenuPopover>
    </>
  );
}
