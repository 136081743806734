import { Link as RouterLink, Outlet, useNavigate } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Typography, IconButton } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { PATH_AUTH } from '../routes/paths';
// components
import Logo from '../components/Logo';
import { MHidden, MIconButton } from '../components/@material-extend';
import LanguagePopover from './dashboard/LanguagePopover';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between'
}));

// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {
  const navigate = useNavigate();
  const Login = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    if (!isValidToken(accessToken)) {
      return (
        <MIconButton
          sx={{
            padding: 0,
            width: 44,
            height: 44
          }}
          onClick={() => navigate(`/auth/login`)}
          size="small"
          fontSize="small"
        >
          <LockIcon />
        </MIconButton>
      );
    }
  };
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo gcolor="#0045FF" />
        </RouterLink>
        <MHidden width="xsDown">
          <Typography
            variant="body2"
            sx={{
              mt: { md: -2 }
            }}
          >
            {Login()}
            <LanguagePopover />
          </Typography>
        </MHidden>
      </HeaderStyle>
      <Outlet />
    </>
  );
}
