import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography, Button, Avatar } from '@material-ui/core';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  backgroundColor: '#97dbff',
  alignItems: 'center',
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method, login } = useAuth();
  const { translate } = useLocales();
  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Pill Device">
      <AuthLayout />
      <MHidden width="mdDown">
        <SectionStyle>
          <img alt="register" src="/static/illustrations/Logowhitepequeño.png" width="400" />
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }} style={{ color: 'white' }}>
            {translate('login.mesage')}
          </Typography>
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {translate('login.signin')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{translate('login.details')}</Typography>
            </Box>
          </Stack>
          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button color="white" fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              {translate('login.inicio')}
            </Button>
          )}
          <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3 }}>
            {translate('login.agree')}
            <Link underline="always" color="text.primary" href="#">
              {translate('login.terms')}
            </Link>
            {translate('login.and')}
            <Link underline="always" color="text.primary" href="#">
              {translate('login.privacy')}
            </Link>
            .
          </Typography>
          <Typography variant="body2" align="center" sx={{ mt: 3 }}>
            {translate('login.account')}
            <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
              {translate('login.started')}
            </Link>
          </Typography>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
