import PropTypes, { string } from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { useState, useEffect } from 'react';
// material
import { alpha, styled, withStyles } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core';
// hooks
import jwtDecode from 'jwt-decode';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import PharmaPopover from './PharmaPopover';
import CenterPopover from './CenterPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();

  const getRole = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);
    console.log(decoded.role);
    return decoded.role;
  };

  const [Pharma, setListUsers] = useState();
  const handleSubmitOder = async (filter) => {
    await axios
      .get(`/api/installation/get_installation_by_id?id=${filter}`, Request)
      .then((res) => {
        if (res) {
          setListUsers(res.data.name);
        }
      })
      .catch((error) => {});
  };
  const [Center, setListCenter] = useState();
  const handleSubmitCenter = async (filter) => {
    await axios
      .get(`/api/center/center?id=${filter}`, Request)
      .then((res) => {
        if (res) {
          setListCenter(res.data.name);
        }
      })
      .catch((error) => {});
  };
  const getIdCenterLogin = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);

    return decoded.center_login;
  };
  const getInstall = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);
    return decoded.installation;
  };
  useEffect(() => {
    handleSubmitOder(getInstall());
    handleSubmitCenter(getIdCenterLogin());
  }, []);

  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
          <PharmaPopover />
          <Stack>
            <Typography variant="h5" color="common.black">
              {Pharma}
            </Typography>
            <Typography variant="h8" color="common.black">
              {Center}
            </Typography>
          </Stack>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          {getRole() === '1' ? <CenterPopover /> : <></>}
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
