// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';
import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  medication: getIcon('ic_medication'),
  patient: getIcon('ic_patient'),
  new: getIcon('ic_new'),
  report: getIcon('ic_report'),
  pharmacy: getIcon('ic_pharmacy'),
  centericon: getIcon('ic_centericon'),
  virtual: getIcon('ic_virtual'),
  traceability: getIcon('ic_traceability'),
  take: getIcon('ic_take'),
  import: getIcon('ic_import'),
  production: getIcon('ic_production')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'catalog',
        path: PATH_DASHBOARD.medicines.list,
        icon: ICONS.medication
      },
      { title: 'patient', path: PATH_DASHBOARD.patients.list, icon: ICONS.patient },
      { title: 'posology', path: PATH_DASHBOARD.posology.list, icon: ICONS.booking },
      { title: 'newpro', path: PATH_DASHBOARD.production.configwork, icon: ICONS.production },
      {
        title: 'report',
        path: PATH_DASHBOARD.report.root,
        icon: ICONS.report,
        children: [
          { title: 'reqwork', path: PATH_DASHBOARD.report.reqwork },
          { title: 'medpatient', path: PATH_DASHBOARD.report.medpatient }
        ]
      },
      {
        title: 'import',
        path: PATH_DASHBOARD.importcontrol.root,
        icon: ICONS.import,
        children: [
          { title: 'upload', path: PATH_DASHBOARD.importcontrol.import },
          { title: 'form', path: PATH_DASHBOARD.importcontrol.rulesimport }
        ]
      },
      {
        title: 'virtual',
        path: PATH_DASHBOARD.virtual.root,
        icon: ICONS.virtual,
        children: [
          { title: 'order', path: PATH_DASHBOARD.virtual.order },
          { title: 'listrx', path: PATH_DASHBOARD.virtual.listrx },
          { title: 'reports', path: PATH_DASHBOARD.virtual.reports },
          { title: 'merge', path: PATH_DASHBOARD.virtual.merge },
          { title: 'parameters', path: PATH_DASHBOARD.virtual.parameters }
        ]
      },
      {
        title: 'traceability',
        path: PATH_DASHBOARD.traceability.root,
        icon: ICONS.traceability,
        children: [
          { title: 'alerts', path: PATH_DASHBOARD.traceability.alerts },
          { title: 'history', path: PATH_DASHBOARD.traceability.history }
        ]
      },
      {
        title: 'take',
        path: PATH_DASHBOARD.takecontrol.root,
        icon: ICONS.take
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'my profile', path: PATH_DASHBOARD.user.account },
          { title: 'list', path: PATH_DASHBOARD.user.list }
        ]
      },
      {
        title: 'centers',
        path: PATH_DASHBOARD.centers.root,
        icon: ICONS.centericon,
        children: [{ title: 'centersmanage', path: PATH_DASHBOARD.centers.manage }]
      },

      // MANAGEMENT : Pharma
      {
        title: 'My pharmacy',
        path: PATH_DASHBOARD.pharma.root,
        icon: ICONS.pharmacy,
        children: [{ title: 'subscription', path: PATH_DASHBOARD.pharma.mypharma }]
      }
    ]
  }
];

export default sidebarConfig;
