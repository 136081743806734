// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import Scrollbar from './components/Scrollbar';
import ThemeLocalization from './components/ThemeLocalization';
import Login from './pages/authentication/Login';
// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized } = useAuth();
  return (
    <Scrollbar>
      <ThemeConfig>
        <ThemePrimaryColor>
          <ThemeLocalization>
            <RtlLayout>
              <NotistackProvider>
                <ScrollToTop />
                <GoogleAnalytics />
                {isInitialized ? <Router /> : <></>}
              </NotistackProvider>
            </RtlLayout>
          </ThemeLocalization>
        </ThemePrimaryColor>
      </ThemeConfig>
    </Scrollbar>
  );
}
