import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import jwtDecode from 'jwt-decode';
import { isValidToken, setSession } from '../../utils/jwt';

export const PusherC = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
  cluster: process.env.REACT_APP_PUSHER_CLUSTER
});

export const PusherCore = () => PusherC;

export const PusherController = (MsgShow) => {
  const channel1 = PusherC.subscribe('general');
  channel1.bind('notices', (data) => {
    MsgShow(data);
  });
};

/*
{
      "variant": "success",
      "message": "Se produjo un error al procesar la solicitud."
}
*/
export const SubscribeNotice = (MsgShow) => {
  const accessToken = window.localStorage.getItem('accessToken');
  if (accessToken && isValidToken(accessToken)) {
    const decoded = jwtDecode(accessToken);
    const channel1 = PusherC.subscribe('general');

    channel1.unbind('nnotices');
    channel1.unbind(`notices_u_${decoded.id_user}`);
    channel1.unbind(`notices_c_${decoded.center_login}`);
    channel1.unbind(`notices_i_${decoded.installation}`);

    channel1.bind('notices', (data) => {
      MsgShow(data);
    });
    channel1.bind(`notices_u_${decoded.id_user}`, (data) => {
      MsgShow(data);
    });
    channel1.bind(`notices_c_${decoded.center_login}`, (data) => {
      MsgShow(data);
    });
    channel1.bind(`notices_i_${decoded.installation}`, (data) => {
      MsgShow(data);
    });
  }
};

export const SubscribeBob = (MsgShow) => {
  const accessToken = window.localStorage.getItem('accessToken');
  if (accessToken && isValidToken(accessToken)) {
    const decoded = jwtDecode(accessToken);
    const channel1 = PusherC.subscribe('bob');
    console.log(`bob_i_${decoded.installation}`);
    channel1.unbind(`bob_i_${decoded.installation}_r`);

    channel1.bind(`bob_i_${decoded.installation}_r`, (data) => {
      MsgShow(data);
    });
  }
};
