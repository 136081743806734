import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
import useLocales from '../hooks/useLocales';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  gcolor: PropTypes.string
};

export default function Logo({ sx, gcolor }) {
  const { translate } = useLocales();
  return (
    <Box sx={{ width: 200, height: 40, ...sx }}>
      <svg id="outputsvg" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 4490 1030">
        <g id="lepOLgycMdrzy2uE9iahjC" fill="#022689">
          <g>
            <path
              id="pDoevDwTT"
              d="M325 849 c-72 -18 -145 -106 -145 -176 0 -34 3 -43 18 -43 9 0 24 15 32 32 8 18 31 42 50 53 32 18 53 20 225 20 160 -1 195 -3 222 -18 38 -20 63 -53 63 -84 0 -16 6 -23 20 -23 17 0 20 7 20 52 0 63 -42 135 -98 170 -34 21 -50 23 -202 25 -91 1 -183 -2 -205 -8z"
            />
            <path
              id="pTJFSWuBF"
              d="M470 600 l0 -40 -40 0 -40 0 0 -40 0 -40 40 0 40 0 0 -40 c0 -39 1 -40 34 -40 33 0 35 2 38 38 3 36 4 37 46 40 42 3 42 3 42 43 l0 39 -45 0 -45 0 0 40 c0 39 -1 40 -35 40 -34 0 -35 -1 -35 -40z"
            />
            <path
              id="pzE6oSiGP"
              d="M383 263 c-13 -2 -23 -7 -23 -11 0 -4 34 -43 75 -87 l75 -80 17 22 c10 11 42 48 71 82 56 63 56 64 -6 75 -29 5 -35 2 -58 -34 l-26 -40 -29 40 c-29 40 -40 44 -96 33z"
            />
          </g>
        </g>
        <g id="l7X5godk3XKTUZX5P7ylYrv" fill="#0045FF">
          <g>
            <path
              id="p13yuIYPWN"
              d="M770 790 c25 -36 35 -93 26 -145 -4 -19 -41 -86 -82 -147 -40 -61 -74 -115 -74 -119 0 -4 24 -9 53 -11 47 -4 55 -2 85 26 71 67 102 185 70 277 -11 34 -84 149 -94 149 -3 0 4 -14 16 -30z"
            />
            <path
              id="p2UqAu1H4"
              d="M195 737 c-67 -115 -59 -231 23 -325 36 -42 39 -44 105 -48 l68 -4 -85 125 c-71 105 -86 133 -91 177 -4 31 -2 65 5 82 17 46 4 42 -25 -7z"
            />
          </g>
        </g>
        <g id="l1g9wSUmJxeBns2InZLe8Sf" fill="#0045FF">
          <g>
            <path
              id="pqpWiXHcz"
              d="M332 413 c-7 -7 -31 -13 -53 -13 -22 0 -39 -4 -37 -9 8 -24 142 -170 150 -165 6 4 27 6 49 6 l39 -1 -24 32 c-13 18 -43 62 -68 98 -36 53 -46 63 -56 52z"
            />
            <path
              id="p1B5uSPyRt"
              d="M601 332 c-40 -61 -57 -94 -49 -96 68 -21 73 -19 147 66 39 44 68 85 65 89 -3 5 -25 9 -49 9 -25 0 -45 5 -47 12 -3 7 -32 -29 -67 -80z"
            />
          </g>
        </g>
        <g id="texts">
          <g>
            <text
              fill="#202020"
              fontFamily="TT Commons Pro"
              x="51%"
              y="49.776543%"
              textAnchor="middle"
              dominantBaseline="central"
              fontSize="569"
            >
              Pill Device
            </text>
          </g>
        </g>
      </svg>
    </Box>
  );
}
