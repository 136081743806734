import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack5';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import Medication from '@material-ui/icons/Medication';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Stack,
  TextField,
  Card,
  CardHeader,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
// routes
import SearchIcon from '@material-ui/icons/Search';
import jwtDecode from 'jwt-decode';
// eslint-disable-next-line import/named
import { isValidToken, setForcrSession } from '../../utils/jwt';
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import SvgIconStyle from '../../components/SvgIconStyle';
// components
import { MIconButton, MAvatar } from '../../components/@material-extend';
import MyAvatar from '../../components/MyAvatar';
import MenuPopover from '../../components/MenuPopover';
import useLocales from '../../hooks/useLocales';
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: PATH_DASHBOARD.user.account
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.pharma.mypharma
  }
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${TableCell.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  [`&.${TableCell.body}`]: {
    fontSize: 14
  }
}));

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  medication: getIcon('ic_medication'),
  patient: getIcon('ic_patient'),
  new: getIcon('ic_new'),
  report: getIcon('ic_report'),
  arrow: getIcon('ic_arrow'),
  pharmacy: getIcon('ic_pharmacy')
};

// ----------------------------------------------------------------------

export default function PharmaPopover() {
  const { translate } = useLocales();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [InstallID, setInstallID] = useState();
  const handleOpen = () => {
    setOpen(getSuperAdmin());
  };
  const handleClose = () => {
    setOpen(false);
  };
  const getSuperAdmin = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);
    console.log(decoded.super_admin);
    return decoded.super_admin;
  };
  const getIdCenterLogin = () => {
    const accessToken = window.localStorage.getItem('accessToken');
    const decoded = jwtDecode(accessToken);
    setCentersProviders(Number(decoded.center_login));
    console.log(decoded.center_login);
    return decoded.center_login;
  };
  function handleOnChange(e) {
    handleSubmitlab(e.target.value);
  }
  const [filter_, setFilter] = useState();
  const [Providers, setProviders] = useState([]);
  const [CenterProviders, setCentersProviders] = useState([]);
  const handleSubmitlab = async (filter) => {
    setFilter(filter);
    // setOpenBackdrop(true);
    await axios
      .get(`/api/installation/get_installation?name=${filter}`, Request)
      .then((res) => {
        if (res) {
          setProviders(res.data);
        }
      })
      .catch((error) => {});
    // setOpenBackdrop(false);
  };
  const handleSubmitCenter = async (filter) => {
    setFilter(filter);
    // setOpenBackdrop(true);
    console.log(filter);
    await axios
      .get(`/api/center/center?id=${filter}`, Request)
      .then((res) => {
        if (res) {
          setProviders(res.data);
        }
      })
      .catch((error) => {});
    // setOpenBackdrop(false);
  };

  const handleSubmitChangeInstall = async (filter) => {
    setFilter(filter);
    // setOpenBackdrop(true);
    await axios
      .get(`/api/installation/change_installation?id=${filter}`, Request)
      .then((res) => {
        setForcrSession(res.data.accessToken);
      })
      .catch((error) => {});
    // setOpenBackdrop(false);
  };

  useEffect(() => {
    handleSubmitlab('');
  }, []);

  const tableStyles = styled({
    tableHead: {
      height: 44
    }
  });
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleOpenDialog = (e) => {
    setInstallID(e);
    setOpenDialog(true);
  };
  const ChangePharma = async (e) => {
    await handleSubmitChangeInstall(InstallID);
    setOpenDialog(false);
    window.location.reload(false);
  };

  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <MAvatar src="/static/icons/navbar/ic_pharmacy.svg" alt="Pharma" />
      </MIconButton>
      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ width: 350 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <TextField
            fullWidth
            label={translate('listmedicine.search')}
            type="search"
            variant="standard"
            onChange={handleOnChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
        </Box>

        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <List>
            {Providers.map((row) => (
              // eslint-disable-next-line react/jsx-key
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    handleOpenDialog(row.id);
                  }}
                >
                  <ListItemIcon>
                    <MAvatar sx={{ width: 25, height: 25 }} src="/static/icons/navbar/ic_pharmacy.svg" alt="Pharma" />
                  </ListItemIcon>
                  <ListItemText primary={row.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <Stack spacing={1}>
            <DialogTitle>Cambio de Farmacia</DialogTitle>
            <DialogContent sx={{ width: 400 }} alignItems="center" justifyContent="center">
              <DialogContentText>Esta seguro que desea Cambiar de Farmacia</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus color="error" variant="contained" onClick={handleCloseDialog}>
                Cancelar
              </Button>
              <Button onClick={ChangePharma} variant="contained" autoFocus>
                Aceptar
              </Button>
            </DialogActions>
          </Stack>
        </Dialog>
      </MenuPopover>
    </>
  );
}
