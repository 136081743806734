import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// hooks
import { useSnackbar } from 'notistack5';
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import { PusherCore, SubscribeNotice } from '../components/pusher/PusherController';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const ShowMesg = (event) => {
    enqueueSnackbar(event.message, {
      variant: event.variant,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      autoHideDuration: 53000,
      onClick: () => {
        closeSnackbar();
      }
    });
  };

  PusherCore();
  SubscribeNotice(ShowMesg);

  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
